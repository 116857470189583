import ApiService from "@/core/services/api.service";

export const UPDATE_APPLICATION = "updateApp";
export const CANCEL_APK_DOWNLOAD = "cancelApkDownload";
export const SET_ERROR = "setError";


const state = {
    errors: []
};

const actions = {
    [UPDATE_APPLICATION](context, body, headers) {
        return new Promise((resolve, reject) => {
            ApiService.put(
                "update",
                body,
                headers
            )
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, "Failed to update application. Please check your application details and try again.");
                    reject(
                        "Failed to update application. Please check your application details and try again."
                    );
                });
        });
    },
};


const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations
};
