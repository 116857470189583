import ApiService from "@/core/services/api.service";

const FileSaver = require('file-saver');

export const DOWNLOAD_APK = "downloadApk";
export const CANCEL_APK_DOWNLOAD = "cancelApkDownload";
export const SET_ERROR = "setError";


const state = {
    errors: []
};

const actions = {
    [DOWNLOAD_APK](context, { params, fileName }) {
        console.log(params, fileName);
        return new Promise((resolve, reject) => {
            ApiService
                .request({
                    url: "applications/d",
                    method: "GET",
                    params,
                    responseType: 'blob'
                })
                // .request("applications/d", { ...params }, { responseType: "blob" })
                .then(({ data }) => {
                    var blob = new Blob([data]);
                    FileSaver.saveAs(blob, fileName);
                    resolve(true);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, [response ? response.data : "Failed to download apk."]);
                    reject(response);
                });
        });
    },
};


const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
};

export default {
    state,
    actions,
    mutations
};
