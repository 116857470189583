import ApiService from "@/core/services/api.service";

export const GET_USERS = "getUsers";
export const GET_USER = "getUser";
export const ADD_USER = "addUser";

export const UPDATE_USER_ACCOUNT = "updateUserAccount";
export const SET_ERROR = "setError";
export const SET_USERS = "setUsers";
export const SET_USER = "setUse";

const state = {
  errors: [],
  users: [],
};

const getters = {
  currentUsers(state) {
    return state.users;
  },
};

const actions = {
  [GET_USERS](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { userId })
        .then(({ data }) => {
          context.commit(SET_USERS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response);
          context.commit(SET_ERROR, [
            response ? response.data : "Failed to fetch users.",
          ]);
          reject(response);
        });
    });
  },
  [GET_USER](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.get("users/" + userId)
        .then(({ data }) => {
          context.commit(SET_USER, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [
            response ? response.data : "Failed to fetch user.",
          ]);
          reject(response);
        });
    });
  },
  [ADD_USER](context, body) {
    return new Promise((resolve, reject) => {
      ApiService.post("users/create", body)
        .then(() => {
          resolve(true);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
  [UPDATE_USER_ACCOUNT](context, body) {
    return new Promise((resolve, reject) => {
      ApiService.put("users", body)
        .then(() => {
          resolve(true);
        })
        .catch(({ response }) => {
          reject(response.data);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_USERS](state, users) {
    state.errors = [];
    state.users = users;
  },
  [SET_USER](state, user) {
    state.errors = [];
    state.user = user;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
