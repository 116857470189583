import ApiService from "@/core/services/api.service";

export const GET_CATEGORIES = "getCategories";
export const DELETE_CATEGORY = "deleteCategory";
export const ADD_CATEGORIES = "addCategory";
export const UPDATE_CATEGORIES = "updateCategory";
export const SET_ERROR = "setError";
export const SET_CATEGORIES = "setUsers";


const state = {
    errors: [],
    categories: []
};


const getters = {
    categories(state) {
        return state.categories;
    },
};

const actions = {
    [GET_CATEGORIES](context) {
        return new Promise((resolve, reject) => {
            ApiService
                .get("categories")
                .then(({ data }) => {
                    context.commit(SET_CATEGORIES, data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    console.log(response);
                    context.commit(SET_ERROR, [response ? response.data : "Failed to fetch categories."]);
                    reject(response);
                });
        });
    },
    [ADD_CATEGORIES](context, body) {
        return new Promise((resolve, reject) => {
            ApiService
                .post("categories/create", body)
                .then(({ data }) => {
                    let appended = [...context.state.categories, { ...data }]
                    context.commit(SET_CATEGORIES, appended);
                    resolve(data);
                })
                .catch(({ response }) => {
                    console.log(response);
                    context.commit(SET_ERROR, [response ? response.data : "Failed to add category. Please check your category details and try again."]);
                    reject(response ? response.data : "Failed to add category. Please check your category details and try again.");
                });
        });
    },
    [UPDATE_CATEGORIES](context, body) {
        return new Promise((resolve, reject) => {
            ApiService
                .put("categories", body)
                .then(({ data }) => {
                    let temp = context.state.categories;
                    temp[body.index]["categoryName"] = body.categoryName;

                    context.commit(SET_CATEGORIES, temp);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, [response ? response.data : "Failed to update category. Please check your category details and try again."]);
                    reject(response ? response.data : "Failed to update category. Please check your category details and try again.");
                });
        });
    },
    [DELETE_CATEGORY](context, body) {
        return new Promise((resolve, reject) => {
            ApiService
                .delete(`categories/${body.categoryId}`)
                .then(({ data }) => {
                    let temp = context.state.categories;
                    temp.splice(body.index, 1);

                    context.commit(SET_CATEGORIES, temp);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, [response ? response.data : "Failed to delete category. Please check your category details and try again."]);
                    reject(response ? response.data : "Failed to delete category. Please check your category details and try again.");
                });
        });
    },
};


const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_CATEGORIES](state, categories) {
        state.errors = [];
        state.categories = categories;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
