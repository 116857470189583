import ApiService from "@/core/services/api.service";
import { SET_ACCOUNT_OBJECT } from "@/core/services/store/auth.module";

import router from "@/router";

export const GET_ORGANISATION = "getOrganisation";
export const GET_ORGANISATIONS = "getOrganisations";
export const DELETE_ORGANISATION = "deleteOrganisation";
export const ADD_ORGANISATION = "addOrganisation";
export const UPDATE_ORGANISATION = "updateOrganisation";
export const SET_ERROR = "setError";
export const SET_ORGANISATION = "setOrganisation";
export const SET_ORGANISATIONS = "setOrganisations";

const state = {
  errors: [],
  organisation: null,
  organisations: [],
};

const getters = {
  organisations(state) {
    return state.organisations;
  },
  organisation(state) {
    return state.organisation;
  },
};

const actions = {
  [GET_ORGANISATION](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`organisation?organisationId=${id}`)
        .then(({ data }) => {
          context.commit(SET_ORGANISATION, data);
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response);
          context.commit(SET_ERROR, [
            response ? response.data : "Failed to fetch organisation.",
          ]);
          reject(response);
        });
    });
  },
  [ADD_ORGANISATION](context, body, _router, isSetup) {
    return new Promise((resolve, reject) => {
      ApiService.post("organisations/create", body)
        .then((res) => {
          //   if (isSetup) {
          // router.push("/dashboard");
          //   }?
          resolve(res.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [
            response
              ? response.data
              : "Failed to add organisation. Please check your organisation details and try again.",
          ]);
          reject(
            response
              ? response.data
              : "Failed to add organisation. Please check your organisation details and try again."
          );
        });
    });
  },
  [UPDATE_ORGANISATION](context, body) {
    return new Promise((resolve, reject) => {
      ApiService.put("organisations", body)
        .then(({ data }) => {
          let temp = context.state.organisations;
          context.commit(SET_ORGANISATION, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [
            response
              ? response.data
              : "Failed to update organisation. Please check your organisation details and try again.",
          ]);
          reject(
            response
              ? response.data
              : "Failed to update organisation. Please check your organisation details and try again."
          );
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ORGANISATION](state, organisation) {
    state.errors = [];
    state.organisation = organisation;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
