import ApiService from "@/core/services/api.service";

export const SEND_MESSAGE_BROADCAST = "sendMessageBroadcast";
export const GET_BROADCAST_MESSAGES = "getBroadcastMessages";
export const SET_BROADCAST_MESSAGES = "setBroadcastMessages";
export const SET_ERROR = "setError";

const state = {
    errors: [],
    broadcastMessages: []
};


const getters = {
    getBroadcastMessages(state) {
        return state.broadcastMessages;
    },
};

const actions = {
    [SEND_MESSAGE_BROADCAST](context, body) {
        return new Promise((resolve, reject) => {
            ApiService
                .post("notify", body)
                .then(({ data }) => {
                    context.commit(SET_BROADCAST_MESSAGES, [...state.broadcastMessages, data]);
                    resolve(true);
                })
                .catch(({ response }) => {
                    reject(typeof response !== "undefined" ? response.data : false);
                });
        });
    },
    [GET_BROADCAST_MESSAGES](context, userId) {
        return new Promise((resolve, reject) => {
            ApiService
                .get(`notify?userId=${userId}`,)
                .then(({ data }) => {
                    resolve(true);
                    context.commit(SET_BROADCAST_MESSAGES, data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, [response ? response.data : "Failed to send message"]);
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_BROADCAST_MESSAGES](state, broadcastMessages) {
        state.broadcastMessages = broadcastMessages;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
