import _ from "lodash";
export const getObjectDifferences = (o1, o2) =>
  _.omit(o1, function(v, k) {
    return o2[k] === v;
  });

export const humanFileSize = (bytes, si = false, dp = 2) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};

export const SERVER_URL =
  // `${location.protocol}//${location.hostname === "localhost" || location.hostname === "127.0.0.1" ? location.hostname + ":8003" : location.hostname}`;
  "https://diapps.co.bw";
