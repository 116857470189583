import ApiService from "@/core/services/api.service";

export const GET_APPLICATIONS = "getApplications";
export const GET_APPLICATION = "getApplication";
export const DELETE_CATEGORY = "deleteApplication";
export const ADD_APPLICATION = "addApplication";
export const UPDATE_APPLICATION = "updateApplication";
export const UPDATE_APPLICATION_STATUS = "updateApplicationStatus";
export const SET_ERROR = "setError";
export const SET_APPLICATIONS = "setApplications";
export const SET_APPLICATION = "setApplication";

const state = {
  errors: [],
  applications: [],
  application: null
};

const getters = {
  applications(state) {
    return state.applications;
  },
  application(state) {
    return state.application;
  }
};

const actions = {
  [GET_APPLICATIONS](context, userId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`applications?userId=${userId}&status=PENDING,APPROVED`)
        .then(({ data }) => {
          context.commit(SET_APPLICATIONS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [
            response ? response.data : "Failed to fetch applications."
          ]);
          reject(response ? response.data : "Failed to fetch applications.");
        });
    });
  },
  [GET_APPLICATION](context, body) {
    return new Promise((resolve, reject) => {
      ApiService.get(`applications/${body.applicationId}?userId=${body.userId}`)
        .then(({ data }) => {
          context.commit(SET_APPLICATION, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [
            response ? response.data : "Failed to fetch applications."
          ]);
          reject(response ? response.data : "Failed to fetch applications.");
        });
    });
  },
  [ADD_APPLICATION](context, body, headers) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "applications/create",
        body,
        headers
      )
        .then(({ data }) => {
          let appended = [...context.state.applications, { ...data }];
          context.commit(SET_APPLICATIONS, appended);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [
            response
              ? response.data
              : "Failed to add application. Please check your application details and try again."
          ]);
          reject(
            response
              ? response.data
              : "Failed to add application. Please check your application details and try again."
          );
        });
    });
  },
  [UPDATE_APPLICATION](context, body, headers) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        "applications",
        body,
        headers
      )
        .then(({ data }) => {
          if (body.applicationName) {
            let temp = context.state.application;
            temp[body.index]["applicationName"] = body.applicationName;

            context.commit(SET_APPLICATION, temp);
          } else
            context.commit(SET_APPLICATION, data);

          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [
            response
              ? response.data
              : "Failed to update application. Please check your application details and try again."
          ]);
          reject(
            response
              ? response.data
              : "Failed to update application. Please check your application details and try again."
          );
        });
    });
  },
  [UPDATE_APPLICATION_STATUS](context, body) {
    return new Promise((resolve, reject) => {
      ApiService.put("applications/status/" + body.applicationId, body)
        .then(({ data }) => {
          context.commit(SET_APPLICATION, data);

          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [
            response
              ? response.data
              : "Failed to update application. Please check your application details and try again."
          ]);
          reject(
            response
              ? response.data
              : "Failed to update application. Please check your application details and try again."
          );
        });
    });
  },
  [DELETE_CATEGORY](context, body) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`applications/${body.applicationId}`)
        .then(({ data }) => {
          let temp = context.state.applications;
          temp.splice(body.index, 1);

          context.commit(SET_APPLICATIONS, temp);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [
            response
              ? response.data
              : "Failed to delete application. Please check your application details and try again."
          ]);
          reject(
            response
              ? response.data
              : "Failed to delete application. Please check your application details and try again."
          );
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_APPLICATIONS](state, applications) {
    state.errors = [];
    state.applications = applications;
  },
  [SET_APPLICATION](state, application) {
    state.errors = [];
    state.application = application;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
