import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import applications from "./applications.module";
import users from "./users.module";
import htmlClass from "./htmlclass.module";
import message_sending from "./message_sending.module";
import categories from "./categories.module";
import downloads from "./downloads.module";
import organisations from "./organisations.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import update from "./update.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    applications,
    users,
    downloads,
    message_sending,
    htmlClass,
    categories,
    organisations,
    config,
    breadcrumbs,
    update
  }
});
