import Vue from "vue";
import Router from "vue-router";
import { mapGetters } from "vuex";
import store from "@/core/services/store";
Vue.use(Router);

export default new Router({
  // mode: 'history',
  mode: "hash",
  baseUrl: "",
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/view/pages/Index"),
    },
    {
      path: "/m",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      beforeEnter: async (to, from, next) => {
        // console.log({ to }, { from });
        // console.log(store.state.auth.isAuthenticated);
        console.log(store.state.auth.user);
        if (!store.state.auth.isAuthenticated) {
          return next("/login");
        } else if (
          store.state.auth.user.accountType === "developer" &&
          typeof store.state.auth.user.organisation === "undefined"
        ) {
          return next("/setup");
        }
        next();
      },
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/dashboard/ListUsers.vue"),
        },
        {
          path: "/users/edit/:id",
          name: "edit users",
          component: () => import("@/view/pages/dashboard/CreateUser.vue"),
        },
        {
          path: "/users/create",
          name: "create-user",
          component: () => import("@/view/pages/dashboard/CreateUser.vue"),
        },
        {
          path: "/organisation",
          name: "organisation",
          component: () => import("@/view/pages/developer/Organisation.vue"),
        },
        {
          path: "/organisation/edit/:id",
          name: "edit-organisation",
          component: () => import("@/view/pages/developer/Organisation.vue"),
        },
        {
          path: "/organisations/create",
          name: "create-organisation",
          component: () => import("@/view/pages/dashboard/CreateUser.vue"),
        },
        {
          path: "/applications",
          name: "applications",
          component: () =>
            import("@/view/pages/dashboard/ListApplications.vue"),
        },
        {
          path: "/applications/delete/:package",
          name: "delete application",
          component: () =>
            import("@/view/pages/dashboard/DeleteApplication.vue"),
        },
        {
          path: "/categories",
          name: "categories",
          component: () => import("@/view/pages/dashboard/ListCategories.vue"),
        },
        {
          path: "/applications/create",
          name: "create-application",
          component: () =>
            import("@/view/pages/dashboard/CreateApplication.vue"),
        },
        {
          path: "/applications/edit/:id",
          name: "edit-application",
          component: () =>
            import("@/view/pages/dashboard/CreateApplication.vue"),
        },
        {
          path: "/update-diapps",
          name: "update-diapps",
          component: () => import("@/view/pages/dashboard/UpdateApp.vue"),
        },
        {
          path: "/send-notification",
          name: "send-notification",
          component: () =>
            import("@/view/pages/dashboard/SendNotification.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/dashboard/Profile.vue"),
        },
        {
          path: "/developer/stats",
          name: "developer-stats",
          component: () => import("@/view/pages/developer/DeveloperStats.vue"),
        },
      ],
    },
    {
      path: "/download/diapps",
      name: "download-diapps",
      component: () => import("@/view/pages/DownloadDiapps.vue"),
    },
    {
      path: "/setup",
      name: "setup-account",
      component: () => import("@/view/pages/developer/SetupAccount.vue"),
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
          beforeEnter: async (to, from, next) => {
            console.log(store.state.auth.isAuthenticated);
            if (store.state.auth.isAuthenticated) {
              return next("/dashboard");
            }
            next();
          },
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
