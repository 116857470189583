import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const UPDATE_PROFILE_PICTURE = "updateProfilePicture";
export const UPDATE_USER = "updateUser";
export const REGISTER = "register";
export const SET_ACCOUNT_OBJECT = "setAccountObject";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_TOKEN = "setToken";
export const SET_ERROR = "setError";
export const SET_USER = "setUser";

const state = {
  errors: [],
  user: window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user"))
    : null,
  isAuthenticated: !!window.localStorage.getItem("user"),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    context.commit(SET_ERROR, null);
    return new Promise((resolve, reject) => {
      state.errors = [];
      const router = credentials.router;
      delete credentials.router;

      ApiService.post("login", credentials)
        .then(async function({ data }) {
          // await context.commit(SET_TOKEN, data);
          // context.commit(PURGE_AUTH);
          await context.commit(SET_AUTH, data);
          resolve(router.push({ name: "dashboard" }));
        })
        .catch((err) => {
          context.commit(SET_ERROR, [
            err.response
              ? err.response.data.message
              : "failed to login. please try again",
          ]);
          reject(err.response);
        });
    });
  },
  [LOGOUT](context) {
    return Promise.resolve(
      // context.commit(SET_USER, null),
      context.commit(PURGE_AUTH)
    );
  },
  [UPDATE_USER](context, body) {
    return new Promise((resolve, reject) => {
      ApiService.put("/users", body)
        .then(async ({ data }) => {
          await context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [
            response ? response.data : "Failed to fetch users.",
          ]);
          reject(response);
        });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("signup", credentials)
        .then(async ({ data }) => {
          await context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.message]);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context, router) {
    if (
      typeof JwtService.getToken() !== "undefined" &&
      JwtService.getToken() !== null &&
      JwtService.getToken()
    ) {
      // ApiService.setHeader();
      return ApiService.get("verify")
        .then(async ({ data }) => {
          if (data.email) await context.commit(SET_AUTH, data);
          else {
            context.commit(PURGE_AUTH);
            // router.push({ name: "login" });
          }
        })
        .catch(({ response }) => {
          // context.commit(PURGE_AUTH);
          // context.commit(SET_AUTH, null);
          router.push({ name: "login" });
          // context.commit(SET_ERROR, [response ? response.data.message : "failed to verify auth"]);
        });
    } else {
      //
      context.commit(PURGE_AUTH);
      // router.push({ name: "login" });
    }
  },
  [UPDATE_PROFILE_PICTURE](context, body, headers) {
    return new Promise((resolve, reject) => {
      ApiService.put("account/update/dp", body, headers)
        .then(({ data }) => {
          if (body.userId) {
            context.commit(SET_AUTH, { ...currentUser, profilePicture: data });
          } else
            return reject(
              "Failed to update profile picture. Please logout, login and try update."
            );

          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [
            response
              ? response.data
              : "Failed to update profile picture. Please check your profile picture details and try again.",
          ]);
          reject(
            response
              ? response.data
              : "Failed to update profile picture. Please check your profile picture details and try again."
          );
        });
    });
  },
  [SET_ACCOUNT_OBJECT](context, user) {
    return Promise.resolve(context.commit(SET_USER, user));
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_AUTH](state, _user) {
    if (_user !== null) {
      state.isAuthenticated = true;
      state.user = _user;
      state.errors = [];
      window.localStorage.setItem("user", JSON.stringify(_user));
      // JwtService.saveToken(_user._id);
    }
  },
  // [SET_TOKEN](state, user) {
  //   state.errors = {};
  //   JwtService.saveToken(user._id);
  // },
  [PURGE_AUTH](state) {
    window.localStorage.clear();
    // JwtService.destroyToken();
    Object.assign(state, {
      errors: [],
      user: null,
      isAuthenticated: false,
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
